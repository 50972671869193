<template>
  <div>
    <div v-if="loading">Loading...</div>
    <div v-else>
      <login-normal v-if="channelConfig.collectFlag" :config="channelConfig" :source="$route.params.source" />
      <login-collect v-else :config="channelConfig" :source="$route.params.source" />
    </div>
  </div>
</template>

<script>
import { channelLog, getChannelConfig } from '@/api/user'
import LoginNormal from '@/components/LoginNormal.vue'
import LoginCollect from '@/components/LoginCollect.vue'

export default {
  name: 'Login',
  components: {
    LoginNormal,
    LoginCollect
  },
  data() {
    return {
      loading: true,
      channelConfig: null
    }
  },
  async created() {
    // 保存渠道信息
    const source = this.$route.params.source
    if (source) {
      localStorage.setItem('source', source)
    }

    // 获取配置
    try {
      const res = await getChannelConfig()
      this.channelConfig = res.data
    } catch (error) {
      console.error('获取配置失败', error)
    } finally {
      this.loading = false
    }
  },
  mounted() {
    channelLog()
  }
}
</script>
