<template>
    <div class="page-container">
        <!-- 头部品牌区 -->
        <div class="brand-header">
        </div>

        <!-- 表单卡片容器 -->
        <div class="form-card">
            <!-- 金额展示区 -->
            <div class="amount-display">
                <div class="max-amount-tip">最高可借200000元，实际以审核为准</div>
                <div class="current-amount">
                    <div class="amount-wrapper">
                        <span class="currency">¥</span>
                        <span class="amount">{{ selectedAmount }}</span>
                    </div>
                    <div class="loan-status">全部借出</div>
                </div>
            </div>

            <!-- 快速金额选择 -->
            <div class="amount-selector">
                <div class="section-title">快速选择</div>
                <div class="select-grid">
                    <div v-for="amount in loanAmounts" :key="amount"
                        :class="['select-item', selectedAmount === amount ? 'active' : '']"
                        @click="selectedAmount = amount">
                        ¥{{ amount.toLocaleString() }}
                    </div>
                </div>
            </div>

            <!-- 借款期限选择 -->
            <div class="period-selector">
                <div class="section-header">
                    <div class="section-title">借多久</div>
                    <div class="interest-tip">年化利率7.2%-24%，实际以审核为准</div>
                </div>
                <div class="select-grid">
                    <div v-for="period in loanPeriods" :key="period.value"
                        :class="['select-item', selectedPeriod === period.value ? 'active' : '']"
                        @click="selectedPeriod = period.value">
                        {{ period.label }}
                        <span v-if="period.recommended" class="recommend-tag">推荐</span>
                    </div>
                </div>
            </div>

            <!-- 还款说明 -->
            <div class="repayment-info">
                <div class="info-left">
                    <div class="info-title">怎么还</div>
                </div>
                <div class="info-right">
                    <div class="repay-type">随借随还</div>
                    <div class="repay-desc">具体方式需根据最终资方而定</div>
                </div>
            </div>

            <!-- 手机号输入 -->
            <div class="phone-input">
                <van-field v-model="loginData.phoneNo" type="tel" maxlength="11" placeholder="请填写本人实名认证手机号" />
            </div>

            <!-- 滑动验证码组件 -->
            <div v-if="config.captchaFlag">
                <PuzzleCode :bind="$attrs" :show="isShow" success-text="验证成功" fail-text="验证失败" slider-text="拖动滑块完成拼图"
                    @success="success" @close="close" />
            </div>

            <!-- 提交按钮 -->
            <div class="submit-button" @click="handleSubmit">
                领取额度
            </div>

            <!-- 协议确认 -->
            <div class="agreement">
                <van-checkbox v-model="agreed" checked-color="#FF6B29" />
                <div class="agreement-text">
                    我已阅读并同意
                    <span class="link" @click="showUserAgreement = true">《用户注册服务协议》</span>
                    <span class="link" @click="showPrivacyAgreement = true">《隐私协议》</span>
                </div>
            </div>

            <!-- 底部说明 -->
            <div class="footer-notes">
                <p>本平台为信息服务平台，贷款资金由第三方合作持牌机构提供</p>
                <p>合作机构年化利率7.2%-24%,实际以审核为准</p>
                <p>具体放款时间和金额、利率以第三方平台展示为准</p>
                <p>贷款有风险，借款需谨慎，请合理消费，避免逾期</p>
                <p>平台提醒：官方不会以任何名义向您收取手续费</p>
                <p>一切贷前收取手续费的行为都是骗子，请注意辨别。</p>
                <p class="company-info">鄂ICP备2021009249号-1</p>
                <p class="contact">官网电话：400-006-1069</p>
            </div>

            <!-- 协议弹窗 -->
            <UserAgreement :isVisible.sync="showUserAgreement" />
            <PrivacyAgreement :isVisible.sync="showPrivacyAgreement" />

            <!-- 添加短信验证码弹窗 -->
            <SmsDialog :show.sync="showSmsDialog" :phone="loginData.phoneNo" :captchaId="loginData.captchaId"
                @confirm="handleSmsConfirm" />
        </div>
    </div>
</template>

<script>
import UserAgreement from '@/views/UserAgreement.vue'
import PrivacyAgreement from '@/views/PrivacyAgreement.vue'
import PuzzleCode from '@/components/PuzzleCode'
import { login, userExists, checkIsExist } from '@/api/user'
import SmsDialog from '@/components/SmsDialog.vue'

export default {
    name: 'LoginNormal',
    props: {
        source: {
            type: String,
            default: ''
        },
        config: {
            type: Object,
            default: () => ({
                appName: '',
                smsFlag: true,
                captchaFlag: true,
                collectFlag: false
            })
        }
    },
    components: {
        UserAgreement,
        PrivacyAgreement,
        PuzzleCode,
        SmsDialog
    },
    data() {
        return {
            selectedAmount: 10000,
            selectedPeriod: 6,
            agreed: true,
            showUserAgreement: false,
            showPrivacyAgreement: false,
            loanAmounts: [10000, 25000, 200000],
            loanPeriods: [
                { value: 6, label: '6个月' },
                { value: 9, label: '9个月' },
                { value: 12, label: '12个月', recommended: true }
            ],
            captchaVerified: false,
            captchaData: null,
            isShow: false,
            userExistsFlag: false,
            loginData: {
                phoneNo: '',
                verifyCode: '',
                captchaId: '',
            },
            showSmsDialog: false,
        }
    },
    mounted() {
    },
    methods: {
        success(data) {
            this.loginData.captchaId = data.requestId;
            this.isShow = false;

            if (this.config.smsFlag) {
                this.showSmsDialog = true;
            } else {
                this.submitLogin();
            }
        },
        close() {
            this.isShow = false;
        },
        handleSmsConfirm(code) {
            this.loginData.verifyCode = code;
            this.submitLogin();
        },
        async submitLogin() {
            if (!this.loginData.phoneNo) {
                this.$toast('请输入手机号');
                return;
            }

            if (!this.userExistsFlag) {
                if (this.config.captchaFlag && !this.loginData.captchaId) {
                    this.isShow = true;
                    return;
                } else if (this.config.smsFlag && !this.loginData.verifyCode) {
                    this.showSmsDialog = true;
                    return;
                }
            }
            try {
                let res = await login(this.loginData)
                if (res.code == 200) {
                    localStorage.setItem('username', this.loginData.phoneNo)
                    localStorage.setItem('token', res.data)
                    let result = await checkIsExist()
                    if (result.data) {
                        this.$router.push('/home')
                    } else {
                        this.$router.push('/auth')
                    }
                }
            } catch (error) {
                this.$toast(error.message)
            }
        },
        async handleSubmit() {
            if (!this.agreed) {
                this.$toast('请阅读并同意相关协议');
                return;
            }

            if (!this.loginData.phoneNo) {
                this.$toast('请输入手机号');
                return;
            }

            if (!/^1\d{10}$/.test(this.loginData.phoneNo)) {
                this.$toast('请输入正确的手机号');
                return;
            }
            const res = await userExists({ phoneNo: this.loginData.phoneNo })
            if (res.data) {
                this.userExistsFlag = true;
                this.submitLogin()
                return;
            } else {
                this.userExistsFlag = false;
            }
            if (this.config.captchaFlag) {
                this.isShow = true;
            } else if (this.config.smsFlag) {
                this.showSmsDialog = true;
            } else {
                this.submitLogin();
            }
        }
    },
    beforeDestroy() {
    }
}
</script>

<style scoped lang="less">
.page-container {
    min-height: 100vh;
    height: 100vh;
    background: #ffebdf;
    overflow: hidden;
    display: flex;
    flex-direction: column;

}

.brand-header {
    flex-shrink: 0;
    padding: 24px 20px;
    background: #ffebdf;
    display: flex;
    align-items: center;
    gap: 12px;

    .brand-name {
        font-size: 0.37rem;
        font-weight: bold;
        color: #000;
    }

    .brand-slogan {
        font-size: 0.32rem;
        color: #999;
    }
}

.form-card {
    flex: 1;
    background: #fff;
    padding: 0 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-bottom: 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.amount-display {
    padding: 32px 20px;
    border-bottom: 1px solid #f0f0f0;

    .max-amount-tip {
        font-size: 0.32rem;
        color: #666;
    }

    .current-amount {
        margin: 28px 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .amount-wrapper {
            display: flex;
            align-items: baseline;

            .currency {
                font-size: 0.53rem;
                font-weight: bold;
                color: #000;
            }

            .amount {
                font-size: 0.96rem;
                font-weight: bold;
                color: #000;
                margin-left: -4px;
            }
        }

        .loan-status {
            font-size: 0.29rem;
            color: #999;
        }
    }
}

.amount-selector,
.period-selector {
    padding: 16px 16px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.section-title {
    font-size: 0.35rem;
    color: #000;
    margin-bottom: 16px;
    font-weight: 500;
}

.section-header {
    margin-bottom: 16px;

    .interest-tip {
        font-size: 0.30rem;

        color: #999;
        margin-top: 4px;
    }
}

.select-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;

    .select-item {
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f8f8f8;
        border-radius: 6px;
        font-size: 0.37rem;
        color: #333;
        position: relative;
        border: 1px solid transparent;
        transition: all 0.3s;

        &.active {
            background: #FFF0EB;
            border-color: #FF6B29;
            color: #FF6B29;
            font-weight: 500;
        }

        .recommend-tag {
            position: absolute;
            top: -10px;
            right: -10px;
            background: #FF6B29;
            color: #fff;
            font-size: 0.24rem;
            padding: 2px 8px;
            border-radius: 10px;
            transform: scale(0.9);
        }
    }
}

.repayment-info {
    padding: 0 20px;
    margin: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info-left {
        .info-title {
            font-size: 0.35rem;
            color: #333;
            font-weight: 500;
        }
    }

    .info-right {
        text-align: right;

        .repay-type {
            font-size: 0.32rem;
            color: #333;
            margin-bottom: 4px;
        }

        .repay-desc {
            font-size: 0.29rem;
            color: #999;
        }
    }
}

.phone-input {
    margin: 0px 16px;
    background: #f8f8f8;

    /deep/ .van-field__body {
        background: #f8f8f8 !important;
        border-radius: 28px;
        padding: 0;
        overflow: hidden;
        transition: all 0.3s ease;
        border: 5px solid transparent;

        input {
            height: 56px;
            font-size: 0.32rem;
            padding: 0 24px;
            background: #f8f8f8;
        }
    }
}

.submit-button {
    margin: 32px 16px;
    padding: 25px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #ffb70a, #ff4f23);
    border-radius: 50px;
    font-size: 0.43rem;
    font-weight: 500;
    color: #fff;
    position: relative;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(255, 107, 41, 0.3);

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 32px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    }

    &:active {
        transform: translateY(1px);
        box-shadow: 0 4px 8px rgba(255, 107, 41, 0.3);
    }
}

.agreement {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .agreement-text {
        font-size: 0.29rem;
        color: #666;
    }

    .link {
        color: #FF6B29;
    }

    :deep(.van-checkbox) {
        .van-checkbox__label {
            display: none;
        }
    }
}

.footer-notes {
    margin-top: 32px;
    padding: 0 32px;
    text-align: center;

    p {
        font-size: 0.24rem;
        color: #999;
        line-height: 1.8;
    }

    .company-info,
    .contact {
        color: #666;
        margin-top: 8px;
        font-size: 0.27rem;
    }
}

#captcha-div {
    margin: 20px 16px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
}
</style>