<template>
    <div class="page-container">
        <!-- 上半部分 -->
        <div class="top-section">
            <div class="header">
                <div class="brand-name">{{ config.appName }}</div>
                <div class="brand-slogan">正规担保 安全方便</div>
            </div>

            <div class="amount-info">
                <div class="amount-label">提现金额(元)</div>
                <div class="amount-value">{{ formatAmount(selectedAmount) }}</div>
                <div class="amount-options">
                    <div v-for="amount in amountOptions" :key="amount.value"
                        :class="['option', selectedAmount === amount.value ? 'active' : '']"
                        @click="handleAmountSelect(amount.value)">
                        {{ amount.label }}
                    </div>
                </div>
            </div>
        </div>

        <!-- 白色容器部分 -->
        <div class="content-card">
            <!-- 借款期限 -->
            <div class="loan-period">
                <div class="section-title">借多久</div>
                <div class="period-options">
                    <div v-for="period in periodOptions" :key="period.value"
                        :class="['option', selectedPeriod === period.value ? 'active' : '']"
                        @click="handlePeriodSelect(period.value)">
                        {{ period.label }}
                    </div>
                </div>
                <div class="line"></div>
            </div>

            <!-- 借款用途 -->
            <div class="loan-purpose" @click="showPurposePopup = true">
                <div class="label">借款用途</div>
                <div class="value">
                    {{ selectedPurpose.label }}
                    <van-icon name="arrow" />
                </div>
            </div>

            <!-- 手机号输入 -->
            <div class="input-field">
                <van-field v-model="loginData.phoneNo" type="tel" maxlength="11" placeholder="请输入手机号" />
            </div>

            <!-- 滑动验证码组件 -->
            <div v-if="config.captchaFlag">
                <PuzzleCode :bind="$attrs" :show="isShow" success-text="验证成功" fail-text="验证失败" slider-text="拖动滑块完成拼图"
                    @success="success" @close="close" />
            </div>

            <!-- 提交按钮 -->
            <div class="submit-button" @click="handleSubmit">
                一键提现
                <div class="pass-rate">通过率最高达99%</div>
            </div>

            <!-- 添加短信验证码弹窗 -->
            <SmsDialog :show.sync="showSmsDialog" :phone="loginData.phoneNo" :captchaId="loginData.captchaId"
                @confirm="handleSmsConfirm" theme-color="#3366FF" />

            <!-- 协议确认 -->
            <div class="agreement">
                <van-checkbox v-model="agreed" checked-color="#3366FF" />
                <div class="agreement-text">
                    我已阅读并同意
                    <span class="link" @click="showUserAgreement = true">《用户注册服务协议》</span>
                    <span class="link" @click="showPrivacyAgreement = true">《隐私协议》</span>
                </div>
            </div>
        </div>

        <!-- 底部说明 -->
        <div class="footer-notes">
            <p>综合年化利率（单利）7.2-24%</p>
            <p>贷款有风险，借款需谨慎</p>
            <p>请根据个人能力合理贷款，理性消费，避免逾期</p>
            <p>贷款额度、放款时间以实际审批结果为准</p>
            <p>鄂ICP备2021009249号-1</p>
        </div>

        <!-- 借款用途选择弹窗 -->
        <van-popup v-model="showPurposePopup" position="bottom" round safe-area-inset-bottom>
            <div class="purpose-popup">
                <div class="popup-header">
                    <div class="popup-title">选择借款用途</div>
                    <van-icon name="cross" @click="showPurposePopup = false" />
                </div>
                <div class="purpose-list">
                    <div v-for="purpose in purposeOptions" :key="purpose.value" class="purpose-item"
                        :class="{ active: selectedPurpose.value === purpose.value }"
                        @click="handlePurposeSelect(purpose)">
                        {{ purpose.label }}
                        <van-icon name="success" v-if="selectedPurpose.value === purpose.value" />
                    </div>
                </div>
            </div>
        </van-popup>

        <!-- 协议弹窗 -->
        <UserAgreement :isVisible.sync="showUserAgreement" />
        <PrivacyAgreement :isVisible.sync="showPrivacyAgreement" />
    </div>
</template>

<script>
import UserAgreement from '@/views/UserAgreement.vue'
import PrivacyAgreement from '@/views/PrivacyAgreement.vue'
import PuzzleCode from '@/components/PuzzleCode'
import { login, userExists } from '@/api/user'
import SmsDialog from '@/components/SmsDialog.vue'

export default {
    name: 'LoginCollect',
    components: {
        UserAgreement,
        PrivacyAgreement,
        PuzzleCode,
        SmsDialog
    },
    props: {
        source: {
            type: String,
            default: ''
        },
        config: {
            type: Object,
            default: () => ({
                appName: '',
                smsFlag: true,
                captchaFlag: true,
                collectFlag: true
            })
        }
    },
    data() {
        return {
            agreed: true,
            showUserAgreement: false,
            showPrivacyAgreement: false,
            isShow: false,
            loginData: {
                phoneNo: '',
                verifyCode: '',
                captchaId: '',
            },
            showSmsDialog: false,
            selectedAmount: 50000,
            amountOptions: [
                { value: 50000, label: '¥50000' },
                { value: 100000, label: '¥100000' },
                { value: 200000, label: '全部借出' }
            ],
            selectedPeriod: 3,
            periodOptions: [
                { value: 3, label: '3个月' },
                { value: 6, label: '6个月' },
                { value: 12, label: '12个月' },
                { value: 24, label: '24个月' }
            ],
            showPurposePopup: false,
            selectedPurpose: { value: 1, label: '日常消费' },
            userExistsFlag: false,
            purposeOptions: [
                { value: 1, label: '日常消费' },
                { value: 2, label: '医疗支出' },
                { value: 3, label: '教育培训' },
                { value: 4, label: '装修家居' },
                { value: 5, label: '旅游出行' },
                { value: 6, label: '其他用途' }
            ],
        }
    },
    methods: {
        success(data) {
            this.loginData.captchaId = data.requestId;
            this.isShow = false;

            if (this.config.smsFlag) {
                this.showSmsDialog = true;
            } else {
                this.submitLogin();
            }
        },
        close() {
            this.isShow = false;
        },
        handleSmsConfirm(code) {
            this.loginData.verifyCode = code;
            this.submitLogin();
        },
        async submitLogin() {
            if (!this.loginData.phoneNo) {
                this.$toast('请输入手机号');
                return;
            }

            if (!this.userExistsFlag) {
                if (this.config.captchaFlag && !this.loginData.captchaId) {
                    this.isShow = true;
                    return;
                } else if (this.config.smsFlag && !this.loginData.verifyCode) {
                    this.showSmsDialog = true;
                    return;
                }
            }

            try {
                const res = await login(this.loginData)
                if (res.code == 200) {
                    localStorage.setItem('username', this.loginData.phoneNo)
                    localStorage.setItem('token', res.data)
                    this.$router.push('/home')
                } else {
                    this.$toast(res.message)
                }
            } catch (error) {
                this.$toast(error.message || '登录失败');
            }
        },
        async handleSubmit() {
            if (!this.agreed) {
                this.$toast('请阅读并同意相关协议');
                return;
            }

            if (!this.loginData.phoneNo) {
                this.$toast('请输入手机号');
                return;
            }

            if (!/^1\d{10}$/.test(this.loginData.phoneNo)) {
                this.$toast('请输入正确的手机号');
                return;
            }
            const res = await userExists({ phoneNo: this.loginData.phoneNo })
            if (res.data) {
                this.userExistsFlag = true;
                this.submitLogin()
                return
            } else {
                this.userExistsFlag = false;
            }

            if (this.config.captchaFlag) {
                this.isShow = true;
            } else if (this.config.smsFlag) {
                this.showSmsDialog = true;
            } else {
                this.submitLogin();
            }
        },
        handleAmountSelect(amount) {
            this.selectedAmount = amount;
        },
        formatAmount(amount) {
            return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        handlePeriodSelect(period) {
            this.selectedPeriod = period;
        },
        handlePurposeSelect(purpose) {
            this.selectedPurpose = purpose;
            this.showPurposePopup = false;
        }
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
}
</script>

<style scoped lang="less">
.page-container {
    min-height: 100vh;
    background: linear-gradient(180deg, #3366FF 0%, #3366FF 30%, #fff 100%);
    padding: 0 20px;
}

.top-section {
    padding-top: 24px;
    color: #fff;

    .header {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 40px;

        .brand-name {
            font-size: 0.48rem;
            font-weight: bold;
        }

        .brand-slogan {
            font-size: 0.37rem;
            opacity: 0.8;
        }
    }

    .amount-info {
        .amount-label {
            font-size: 0.43rem;
            opacity: 0.8;
        }

        .amount-value {
            font-size: 1.8rem;
            font-weight: bold;
            margin: 16px 0 24px;
        }

        .amount-options {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 12px;

            .option {
                height: 56px;
                padding: 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(255, 255, 255, 0.15);
                border: 1px solid rgba(255, 255, 255, 0.3);
                font-size: 0.43rem;
                min-width: 120px;
                cursor: pointer;
                transition: all 0.3s ease;
                position: relative;

                &.active {
                    background: rgba(255, 255, 255, 0.3);
                    border: 2px solid #fff;
                    font-weight: bold;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background: linear-gradient(180deg,
                                rgba(255, 255, 255, 0.2) 0%,
                                rgba(255, 255, 255, 0) 100%);
                    }
                }

                &:active {
                    transform: scale(0.98);
                }
            }
        }
    }
}

.content-card {
    background: #fff;
    border-radius: 16px;
    padding: 24px 20px;
    margin-top: 32px;
    box-shadow: 0 -4px 16px rgba(0, 0, 0, 0.05);

    .loan-period {
        padding-bottom: 20px;
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 20px;

        .section-title {
            font-size: 0.43rem;
            color: #333;
            margin-bottom: 16px;
        }

        .period-options {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 12px;

            .option {
                height: 56px;
                padding: 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #F0F5FF;
                border: 1px solid #E6E6E6;
                font-size: 0.43rem;
                min-width: 100px;
                color: #333;
                cursor: pointer;
                transition: all 0.3s ease;

                &.active {
                    background: #3366FF;
                    color: #fff;
                    border-color: #3366FF;
                    font-weight: bold;
                    box-shadow: 0 2px 8px rgba(51, 102, 255, 0.3);

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background: linear-gradient(180deg,
                                rgba(255, 255, 255, 0.2) 0%,
                                rgba(255, 255, 255, 0) 100%);
                    }
                }

                &:active {
                    transform: scale(0.98);
                }
            }
        }
    }

    .loan-purpose {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #f5f5f5;
        cursor: pointer;

        .label {
            font-size: 0.43rem;
            color: #333;
        }

        .value {
            display: flex;
            align-items: center;
            font-size: 0.43rem;
            font-weight: 500;
            color: #333;

            .van-icon {
                margin-left: 4px;
                color: #999;
                font-size: 0.37rem;
            }
        }
    }

    .input-field {
        margin: 16px 0;

        :deep(.van-field__body) {
            background: #fff;

            input {
                height: 56px;
                font-size: 0.43rem;
                padding: 0 16px;
                background: #fff;
            }
        }

        :deep(.van-field) {
            border: 1px solid #E6E6E6;
            border-radius: 28px;
            padding: 0 8px;
        }

        :deep(.van-button) {
            height: 40px;
            border-radius: 20px;
            padding: 0 20px;
            background: #FF6B29;
            border-color: #FF6B29;
            font-size: 0.37rem;

            &--disabled {
                background: #E6E6E6;
                border-color: #E6E6E6;
                color: #999;
            }

            &:active {
                background: darken(#FF6B29, 10%);
                border-color: darken(#FF6B29, 10%);
            }
        }

        &:focus-within {
            :deep(.van-field) {
                border-color: #3366FF;
            }
        }
    }

    .submit-button {
        margin: 24px 0;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #3366FF;
        font-size: 0.48rem;
        color: #fff;
        position: relative;
        padding: 10px 20px;
        border-radius: 50px;

        .pass-rate {
            position: absolute;
            top: -28px;
            right: 20px;
            background: #FF6B29;
            color: #fff;
            font-size: 0.32rem;
            padding: 8px 16px;
            border-radius: 16px;
            box-shadow: 0 2px 8px rgba(255, 107, 41, 0.3);

            &::after {
                content: '';
                position: absolute;
                bottom: -6px;
                right: 20px;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid #FF6B29;
            }
        }
    }
}

.footer-notes {
    padding: 24px 0;
    text-align: center;

    p {
        font-size: 0.32rem;
        color: #999;
        line-height: 1;
    }
}

.purpose-popup {
    .popup-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid #f5f5f5;

        .popup-title {
            font-size: 0.43rem;
            font-weight: 500;
            color: #333;
        }

        .van-icon {
            font-size: 0.48rem;
            color: #999;
            padding: 8px;
        }
    }

    .purpose-list {
        max-height: 60vh;
        overflow-y: auto;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);

        .purpose-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            font-size: 0.43rem;
            color: #333;
            border-bottom: 1px solid #f5f5f5;

            &.active {
                color: #3366FF;
                font-weight: 500;

                .van-icon {
                    color: #3366FF;
                }
            }

            .van-icon {
                font-size: 0.48rem;
            }

            &:active {
                background: #f8f8f8;
            }
        }
    }
}

.agreement {
    padding: 0 20px;
    display: flex;
    align-items: flex-start;
    gap: 8px;

    .agreement-text {
        font-size: 0.37rem;
        color: #666;
        padding-top: 4px;
        line-height: 1.5;

        .link {
            color: #3366FF;
            display: inline-block;
            margin-top: 4px;
        }
    }

    :deep(.van-checkbox) {
        padding-top: 8px;

        .van-checkbox__label {
            display: none;
        }
    }
}
</style>