<template>
    <van-dialog v-model="visible" title="验证码" :show-confirm-button="false" class="sms-dialog">
        <div class="sms-content">
            <div class="phone-info">验证码已发送至 {{ formatPhone }}</div>

            <div class="sms-code">
                <van-field v-model="smsCode" type="tel" maxlength="6" placeholder="请输入验证码" :border="false" center>
                    <template #button>
                        <van-button size="small" type="default" :disabled="counting > 0" @click="handleResend">
                            {{ counting > 0 ? `${counting}s` : '重新发送' }}
                        </van-button>
                    </template>
                </van-field>
            </div>
            <div class="btn-confirm">
                <div class="btn-confirm-text" :style="{ background: themeColor }" @click="handleConfirm">确认</div>
            </div>
        </div>
    </van-dialog>
</template>

<script>
import { sendSms } from '@/api/user'

export default {
    name: 'SmsDialog',
    props: {
        show: Boolean,
        phone: String,
        captchaId: String,
        themeColor: {
            type: String,
            default: '#FF6B29'
        }
    },
    data() {
        return {
            visible: false,
            smsCode: '',
            counting: 0,
            timer: null
        }
    },
    computed: {
        formatPhone() {
            if (!this.phone) return ''
            return `${this.phone.substr(0, 3)}****${this.phone.substr(7)}`
        }
    },
    watch: {
        show(val) {
            this.visible = val
            if (val) {
                this.counting = 0
                this.smsCode = ''
                this.handleResend()
            }
        },
        visible(val) {
            if (!val) {
                this.$emit('update:show', false)
            }
        }
    },
    methods: {
        async handleResend() {
            try {
                await sendSms({
                    phoneNo: this.phone,
                    captchaId: this.captchaId
                })
                this.startCount()
                this.$toast('验证码已发送')
            } catch (error) {
                this.$toast(error.message || '发送失败')
            }
        },
        startCount() {
            this.counting = 60
            clearInterval(this.timer)
            this.timer = setInterval(() => {
                if (this.counting > 0) {
                    this.counting--
                } else {
                    clearInterval(this.timer)
                }
            }, 1000)
        },
        handleConfirm() {
            if (!this.smsCode) {
                this.$toast('请输入验证码')
                return
            }
            this.$emit('confirm', this.smsCode)
            this.visible = false
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)
    }
}
</script>

<style lang="less" scoped>
.sms-dialog {
    .sms-content {
        padding: 20px;

        .phone-info {
            text-align: center;
            color: #666;
            font-size: 32px;
            margin-bottom: 20px;
        }

        .sms-code {
            /deep/ .van-field__body {
                padding: 0;

                input {
                    font-size: 32px;
                    background: #f8f8f8;
                    padding: 10px 16px;
                    border-radius: 16px;
                }
            }
        }

        .btn-confirm {
            margin-top: 10px;

            .btn-confirm-text {
                padding: 10px 20px;
                height: 44px;
                margin: 10px 0px;
                text-align: center;
                background: #FF6B29;
                color: #fff;
                border-radius: 22px;
                font-size: 32px;
            }
        }
    }
}
</style>